import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";
import get from "lodash/get";
import Layout from "../components/layout";
import ScrollSection from "../components/scroll-section/scroll-section";
import Seo from "../components/seo/seo";
import { MONTHS, URLS } from "../config";
import TransitionLink from "../components/transition-link/transition-link";
import Image from "../components/image/image";

class ContientPageTemplate extends React.Component {
  // https://www.jasonpontin.com/
  render() {
    const posts = get(this.props, "data.allContentfulBlog.edges");
    const category = get(this.props, "data.contentfulTrip");
    const { displayTitle, preTitle, slug, subtitle } = category;

    return (
      <Layout
        classNames={{
          container: cx("bg-palette-gray"),
        }}
        location={this.props.location}
        status={this.props.transitionStatus}
        showMenu={false}
      >
        <Seo
          type="page"
          content={{
            title: `${displayTitle} Travel`,
            seoDescription: `${displayTitle} Travel`,
          }}
        />

        <ScrollSection>
          <div id="blog-archive" className="blog-archive relative">
            <div className="bg-primary-red fixed top-0 h-screen w-full blog-hero flex items-center justify-center">
              <h1
                style={{ fontSize: "16.5vw" }}
                className="font-semibold text-black relative -mt-2 z-10"
              >
                {displayTitle.toUpperCase()}
              </h1>
              <Image
                alt={`${displayTitle} Hero`}
                className={cx(
                  "!absolute mix-blend-multiply top-0 left-0 w-full h-screen block"
                )}
                gatsbyImageData={category.heroImage}
                onLoad={() => {
                  document.body.classList.add("page-ready");
                }}
              />
            </div>
            <div className="blog-archive__articles py-40 bg-palette-gray relative">
              {preTitle || subtitle ? (
                <div className="lg:flex xl:w-4/5 float-right">
                  <div className="lg:flex px-5.5 xl:px-0 mx-auto w-full">
                    {preTitle ? (
                      <p className="font-bold xl:px-5.5 md:pl-0 text-black text-3xl mb-4 lg:w-3/12">
                        {preTitle}
                      </p>
                    ) : (
                      ""
                    )}
                    {subtitle ? (
                      <p className="font-display text-black lg:px-5.5 text-lg mb-40 lg:w-1/2">
                        {subtitle}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <ul className="flex flex-col items-end w-full my-10">
                {posts.map(({ node }, index) => {
                  const limit = 200;
                  let excerpt = node.excerpt?.childMarkdownRemark.html;

                  if (excerpt && excerpt.length > limit) {
                    excerpt = excerpt.substring(0, 200);
                    excerpt = `${excerpt.substr(
                      0,
                      Math.min(excerpt.length, excerpt.lastIndexOf(" "))
                    )}...`;
                  }

                  if (index === 0) {
                    return (
                      <li
                        className="xl:flex flex-row-reverse px-0 mb-20 w-full"
                        key={node.slug}
                      >
                        {node.heroImage ? (
                          <TransitionLink
                            to={`${URLS.Blog}${slug}/${node.slug}`}
                            className="animate-opacity-in block w-full mb-8 xl:mb-0 xl:mr-5.5 xl:w-7/12"
                            data-cursor-type="blog"
                          >
                            <Image
                              alt={`${node.title} Hero`}
                              gatsbyImageData={node.heroImage}
                            />
                          </TransitionLink>
                        ) : (
                          ""
                        )}
                        <div className="w-full xl:w-5/12">
                          <div className="xl:flex px-5.5 items-center h-full w-full">
                            <div className="lg:px-5.5">
                              <div className="font-light leading-snug text-sm lg:text-md text-black mb-8">
                                <strong className="font-semibold mr-4 pr-4 border-r border-black">
                                  {node.country.title}
                                </strong>
                                {MONTHS[new Date(node.publishDate).getMonth()]}{" "}
                                {new Date(node.publishDate).getDate()},{" "}
                                {new Date(node.publishDate).getFullYear()}
                              </div>
                              <TransitionLink
                                className="text-xl sm:text-3xl xl:text-xl 2xl:text-3xl text-primary-red hover:text-red-accessible"
                                to={`${URLS.Blog}${slug}/${node.slug}`}
                                data-cursor-type="blog"
                              >
                                <h2 className="font-semibold mb-8 leading-tight">
                                  {node.title}
                                </h2>
                              </TransitionLink>
                              <div
                                className={cx(
                                  "font-light leading-snug text-md lg:text-lg lg:w-5/6"
                                )}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    node.excerpt?.childMarkdownRemark.html,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  }
                  return (
                    <li
                      className="border-t lg:flex border-gray-400 px-5.5 lg:pl-0 md:mb-0 w-full lg:w-4/5 py-10"
                      key={node.slug}
                    >
                      <div className="font-light mt-2 leading-snug text-sm lg:text-md text-black mb-4 lg:mb-8 lg:w-3/12">
                        <div>
                          <strong className="font-semibold mr-4 pr-4 border-r border-black">
                            {node.country.title}
                          </strong>
                          {MONTHS[new Date(node.publishDate).getMonth()]}{" "}
                          {new Date(node.publishDate).getDate()},{" "}
                          {new Date(node.publishDate).getFullYear()}
                        </div>
                      </div>
                      <div className="lg:px-5.5 lg:w-7/12">
                        <TransitionLink
                          className="text-lg lg:text-xl text-primary-red hover:text-red-accessible"
                          to={`${URLS.Blog}${slug}/${node.slug}`}
                          data-cursor-type="blog"
                        >
                          <h3 className="font-semibold mb-8 lg:mb-0 leading-tight">
                            {node.title}
                          </h3>
                        </TransitionLink>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </ScrollSection>
      </Layout>
    );
  }
}

export default ContientPageTemplate;

export const pageQuery = graphql`
  query BlogArchiveQuery($id: String!) {
    contentfulTrip(id: { eq: $id }) {
      displayTitle
      preTitle
      subtitle
      id
      slug
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    allContentfulBlog(
      sort: { fields: [publishDate], order: DESC }
      filter: { trip: { id: { eq: $id } } }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate
          minuteRead
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          country {
            slug
            title
            category {
              slug
              title
            }
            continent {
              title
              slug
            }
          }
          author {
            displayTitle
          }
          excerpt {
            excerpt
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
